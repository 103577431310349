import { MediaMatcher } from '@angular/cdk/layout';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectorRef,
  Component,
  Input, ViewChild
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { environment } from '@environments/environment';
import { AppService } from 'ets-fe-ng-sdk';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { UtilityService } from '@Services/utility.service';
//import { MenuItem } from '@Shared/models/IMenuItem';
import { Router, RouterLink, RouterModule } from '@angular/router';
import PS from 'perfect-scrollbar';
import { SalesMenuItem } from 'projects/evolutics-sales-ui/src/app/models/sales.IMenuItem';
import { SalesAppService } from 'projects/evolutics-sales-ui/src/app/services/sales-app.service';
import { MenuItem } from '@Shared/models/IMenuItem';
import { IsNavMenuEnabledPipe } from '@Shared/pipes/utility.pipe';
import { TranslatePipe } from '../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { ImageLoaderDirective } from '../../../../../evolutics-shared-lib/src/lib/Shared/directives/index.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'sui-vertical-nav',
    templateUrl: './vertical-nav.component.html',
    styleUrls: ['./vertical-nav.component.scss'],
    imports: [
        NgClass,
        MatSidenavModule,
        MatTreeModule,
        RouterLink,
        MatButtonModule,
        MatMenuModule,
        ImageLoaderDirective,
        AsyncPipe,
        TranslatePipe,
        RouterModule
    ],
    providers: [IsNavMenuEnabledPipe]
})
export class VerticalNavComponent {
  @Input() fullScreen: boolean;
  @Input() isHome: boolean;
  @Input() hideMenu: boolean;
  @Input() showSearch: boolean;
  @Input() title: string;
  @Input() set menuItems(v: MenuItem[]) {
    if (!v?.length) return;
    v.forEach((x) => (x.level = 0));
    this.dataSource = new MatTreeNestedDataSource<MenuItem>();
    this.dataSource.data = v;
  }

  id = 'matSideNav' + this.uS.genRandomID;
  helpLink = environment.helpLink;
  user = environment.userProfile;
  treeControl = new NestedTreeControl<MenuItem>((node) => node.subs);

  dataSource: MatTreeNestedDataSource<MenuItem>;

  @ViewChild('snav') drawer: MatDrawer;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appS: AppService,
    public uS: UtilityService,
    public router: Router,
    public authS: AuthenticationService,
    public imeS: IsNavMenuEnabledPipe,
    public sappS: SalesAppService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    console.log(sappS.salesBottomMenu);
  }

  hasChild = (_: number, node: MenuItem) => node.hasSub && this.shouldShow(_, node);
  shouldShow = (_: number, node: MenuItem) =>
    this.isHome ? this.imeS.transform(node)?.enabled : true;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit(): void {
    this.drawer.open();
    const doc = document.querySelector<HTMLDivElement>(`#${this.id} .mat-drawer-inner-container`);
    if (doc) {
      doc.style.position = 'relative';
      const ps = new PS(doc, {
        wheelSpeed: 0.5,
        swipeEasing: false,
        wheelPropagation: true,
        minScrollbarLength: 40,
      });
    }
  }

  setCurrentMenu(item: MenuItem) {
    this.sappS.setCurrentTopMenu(item.system);
  }
  signOut() {
    this.uS.startLoader();
    this.authS.logout().then(() => this.router.navigateByUrl('/'));
  }
}
