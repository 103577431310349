import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterLink } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SalesAppService } from 'projects/evolutics-sales-ui/src/app/services/sales-app.service';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { environment } from '@environments/environment';
import { AppService } from '@Services/app.service';
import { StorageService } from '@Services/storage.service';
import { UtilityService } from '@Services/utility.service';
import { NavbarSearchComponent } from '@Shared/components/search/search-components/navbar-search/navbar-search.component';
import { MenuItem } from '@Shared/models/IMenuItem';
import { UsersService } from '@User/adminuser/user-extras/users.service';
import { TranslatePipe } from '../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MenuItemComponent } from '../../../../../evolutics-shared-lib/src/lib/Layout/navigation/bottom-nav/menu-item/menu-item.component';
import { PSDirective, ImageLoaderDirective } from '../../../../../evolutics-shared-lib/src/lib/Shared/directives/index.directive';
import { ToAnyPipe } from 'ets-fe-ng-sdk';

@Component({
    selector: 'sui-bottom-nav',
    templateUrl: './bottom-nav.component.html',
    styleUrls: ['./bottom-nav.component.scss'],
    imports: [
        PSDirective,
        MenuItemComponent,
        ImageLoaderDirective,
        RouterLink,
        AsyncPipe,
        ToAnyPipe,
        TranslatePipe,
    ]
})
export class BottomNavComponent implements OnInit {
  system: string = '';
  navColor = '';
  type = '';
  appRoutes = environment.appRoutes;
  bottomMenu = environment.bottomMenu;

  id = 71;
  user = environment.userProfile;

  menu: MenuItem[] = [];
  helpLink = environment.helpLink;
  @ViewChild('search') searchRef: NavbarSearchComponent;
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UsersService,
    private storage: StorageService,
    public appS: AppService,
    public sappS: SalesAppService,
    public authS: AuthenticationService,
    public uS: UtilityService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: any) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route: any) => route.data)
      )
      .subscribe((event: any) => {
        console.log(event);
        // console.log(this.system);
        this.navColor = event['color']?.toString();

        // console.log(this.navColor);
        if (event['system']) {
          // this.system = event['system'].toString();
        }

        if (event['type']) {
          this.type = event['type']?.toString();
        }
      });
  }

  ngOnInit(): void {
    console.log(this.sappS.salesBottomMenu)
  }
  ngAfterViewInit(): void {}
  signOut() {
    this.uS.startLoader();
    this.authS.logout().then(() => this.router.navigateByUrl('/'));
  }
  tractById = (index, item: MenuItem) => {
    return item.id;
  };
}
