import { IGetQuery, IObjectLiteral, ITableCol } from 'ets-fe-ng-sdk';

export interface ICMSContent {
  // columns: Pick<ITableCol, 't'>[];
  columns: string[];
  language: string;
  originalId: string;
  rows: ICMSContentRow[];
  text: string;
  title: string;
  type: ECMSType;
  url?: string;
  useCase?: string;
  id?: string;
  docId?: string;
  meta?: { fileExtension?: string; hideTitle?: boolean; displayFileContent?: boolean };
  versionNo?: string;
  // _relationship?: ICMSRelationship;
}
type ICMSContentRow =
  | IObjectLiteral
  | {
      title?: string;
      text?: string;
    };
export enum ECMSType {
  accordion = 'ACCORDION',
  file = 'FILE',
  image = 'IMAGE',
  table = 'TABLE',
  text = 'TEXT',
  url = 'URL',
}

export interface ICMSAccordion extends ICMSContent {
  type: ECMSType.accordion;
  rows: {
    title?: string;
    text?: string;
  }[];
}
export interface ICMSTable extends ICMSContent {
  type: ECMSType.table;
  rows: IObjectLiteral[];
}
export interface ICMSResource extends ICMSContent {
  _inView?: boolean;
  _hidePreview?: boolean;
  _id?: string;
  _parentID?: string;
  _order?: number;
  _level?: number;
  subHelpGuideContent?: ICMSResource[];
}
export interface IAddCMSContentEvent {
  // existingContent: ICMSResource;
  // isChild: boolean;
  previousContent: ICMSContent;
}
export interface ICMSReference {
  helpGuideContentId?: string;
  helpGuideContentTitle?: string;
  refCat?: ECMSContentRefCat;
  refId?: string;
  id?: string;
  applicationCode?: string;
  moduleType?: ECMSModuleType;
}
export interface ICMSResourceQuery extends Partial<Omit<ICMSReference, 'id'>> {
  language?: string;
  versionNo?: string;
}

export interface ICMSRelationship {
  childId: string;
  order: number;
  parentId: string;
  id?: string;
}

export enum ECMSContentRefCat {
  path = 'PAGE_PATH',
  menuItem = 'MENU_ITEM',
  endpoint = 'ENDPOINT',
  database = 'DATABASE',
}

export enum ECMSModuleType {
  help = 'HELP',
  api = 'API',
  database = 'DATABASE',
}

export type ISearchCMSContent = IGetQuery<
  Pick<ICMSContent, 'language' | 'originalId' | 'text' | 'title' | 'type' | 'url' | 'docId' | 'versionNo'>
>;

export interface ICMSReferenceTreeItem {
  label: string;
  id?: string;
  link?: string;
  icon?: string;
  queryParams?:ICMSReference;
  subs?: ICMSReferenceTreeItem[];
}
