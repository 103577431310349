import { computed, inject, Injectable, signal } from '@angular/core';
import { IUserHelperModule, EUserHelperID, IUserHelperConfig } from './user-helper.model';
import { of } from 'rxjs';
import {
  ECMSContentRefCat,
  ECMSModuleType,
  ICMSResource,
} from '../../../../Reusables/reusable-pages/cms/cms.model';
import { Observable } from 'rxjs';
import { UrlSegment } from '@angular/router';
import { CMSService } from '../../../../Reusables/reusable-pages/cms/cms.service';

@Injectable({
  providedIn: 'root',
})
export class UserHelperService {
  readonly cmsService = inject(CMSService);
  protected readonly _modules = signal<IUserHelperModule[]>([
    { id: EUserHelperID.chat, label: 'Chat', icon: 'fa fa-mail-bulk' },
    { id: EUserHelperID.quote, label: 'Quote', icon: 'fa fa-clipboard-list' },
    { id: EUserHelperID.note, label: 'Notes', icon: 'fa fa-note-sticky' },
    { id: EUserHelperID.task, label: 'Tasks', icon: 'fa fa-list' },
    { id: EUserHelperID.notification, label: 'Notifications', icon: 'fa fa-bell' },
    {
      id: EUserHelperID.help,
      label: 'Help',
      icon: 'fa fa-question-circle',
    },
    { id: EUserHelperID.dictionary, label: 'Dictionary', icon: 'fa fa-book' },
  ]);
  protected readonly _config = signal<IUserHelperConfig>({});
  readonly show = signal(false);
  // readonly show = signal(true);

  readonly config = computed(() => ({ ...this._config() }));
  readonly modules = computed(() => {
    const config = this._config() || {},
      ret: IUserHelperModule[] = [];
    for (const x of this._modules() || []) {
      if (config.hiddenModules?.[x.id]) continue;
      ret.push({ ...x, ...(config?.moduleProperties?.[x.id] || {}) });
    }
    return ret;
  });

  updateConfig(config: Partial<IUserHelperConfig>) {
    this._config.update((map) => ({ ...map, ...config }));
  }

  searchHelp = (query: { path: string }) =>
    this.cmsService.getPageRelatedResources({
      applicationCode: this._config()?.applicationCode,
      moduleType: ECMSModuleType.help,
      refCat: ECMSContentRefCat.path,
      refId: query.path,
    });

  toggle() {
    this.show.set(!this.show());
  }
}
